<template>
  <div class="modal-wrap modal-gps">
    <div class="modal-container">
      <div class="gps_wrap" @click.prevent="onClickSubmit">
        <img :src="getGpsGuideImage" alt="image" />
      </div>
      <div class="modal_btn">
        <a class="btn_close close-reveal-modal" @click.prevent="onClickSubmit">
          {{ $t("common.submit") }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Kakao from "@/assets/img/gps_and_kakao.jpg";
import KakaoIOS from "@/assets/img/gps_ios_kakao.jpg";
import Samsung from "@/assets/img/gps_and_samsung.jpg";
import SafariIOS from "@/assets/img/gps_ios_safari.jpg";

export default {
  name: "GpsGuidePopup",
  data() {
    return {
      Kakao,
      KakaoIOS,
      Samsung,
      SafariIOS,
      type: 0,
    };
  },
  computed: {
    getGpsGuideImage() {
      const images = [Kakao, KakaoIOS, Samsung, SafariIOS];
      return images[this.type];
    },
  },
  methods: {
    showPopup(type) {
      this.type = type;
    },
    onClickSubmit() {
      this.$emit("submit");
    },
  },
};
</script>

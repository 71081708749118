<template>
  <section id="check">
    <div class="gps_guide_wrap">
      <p class="stitle">{{ ccName }} {{ checkInName }}</p>
      <div class="gps_guide">
        {{ $t("gpsGuideHeaderMessage._1") }}
        <br />
        {{
          $t("gpsGuideHeaderMessage._2", [checkInName, getCheckInDistanceLabel])
        }}
      </div>
    </div>
    <dl class="dl_gps_guide">
      <dt>{{ $t("gpsGuideBodyMessage._1") }}</dt>
      <dd>
        <p>
          {{ $t("gpsGuideBodyMessage._2", [checkInName]) }}
        </p>
        <p>
          {{ $t("gpsGuideBodyMessage._3") }}
        </p>
        <a class="btn_kakao" @click="onClickOpenGpsGuidePopup(isIOS ? 1 : 0)">
          {{ $t("gps.guide", [$t("gps.kakao")]) }}
        </a>
        <a
          v-if="!isIOS"
          class="btn_samsung"
          @click="onClickOpenGpsGuidePopup(2)"
        >
          {{ $t("gps.guide", [$t("gps.samsung")]) }}
        </a>
        <a v-if="isIOS" class="btn_safari" @click="onClickOpenGpsGuidePopup(3)">
          {{ $t("gps.guide", [$t("gps.safari")]) }}
        </a>
        <a class="btn_back" @click="goBack">
          {{ $t("common.back") }}
        </a>
      </dd>
    </dl>
    <gps-guide-popup
      v-if="isGpsGuidePopupOpened"
      ref="gpsGuidePopup"
      @submit="onGpsGuidePopupSubmit"
    />
  </section>
</template>

<script>
import GpsGuidePopup from "@/components/guides/popup/GpsGuidePopup";
import { mapGetters } from "vuex";

export default {
  name: "SettingGps",
  components: { GpsGuidePopup },
  data() {
    return {
      isGpsGuidePopupOpened: false,
    };
  },
  computed: {
    ...mapGetters(["ccName", "checkInName", "checkInDistance"]),
    isIOS() {
      const varUA = navigator.userAgent.toLowerCase();
      return (
        varUA.indexOf("iphone") > -1 ||
        varUA.indexOf("ipad") > -1 ||
        varUA.indexOf("ipod") > -1
      );
    },
    getCheckInDistanceLabel() {
      return this.checkInDistance > 1000
        ? `${Math.floor(this.checkInDistance / 1000)}km`
        : `${this.checkInDistance}m`;
    },
  },
  methods: {
    async goBack() {
      await this.$router.go(-1);
    },
    async onClickOpenGpsGuidePopup(type) {
      this.isGpsGuidePopupOpened = true;
      await this.$nextTick();
      this.$refs.gpsGuidePopup.showPopup(type);
    },
    onGpsGuidePopupSubmit() {
      this.isGpsGuidePopupOpened = false;
    },
  },
};
</script>
